import React from "react";
import { StyledNav, StyledPage, StyledPageContent } from "./Page.styled";
import { ReactComponent as Logo } from "./logo.svg";

export default function Page(props) {
  return (
    <StyledPage>
      <StyledNav>
        <Logo style={{ width: "15px", marginLeft: "5px" }} />
      </StyledNav>
      <StyledPageContent>{props.children}</StyledPageContent>
    </StyledPage>
  );
}
