// import * as Styled from "./HomePage.styled";

import Page from "../Page/Page";
import {
  StyledFooter,
  StyledHomepage,
  StyledIframe,
  StyledIframeWrapper,
  StyledMainHeader,
  StyledSignInLink,
  StyledSignUpButton,
  StyledSlash,
  StyledSlashWrapper,
  StyledSubHeading,
  StyledTextDescription,
  StyledTextWrapper,
} from "./Home.styled";

const Home = (props) => {
  return (
    <Page>
      <StyledHomepage>
        <StyledTextWrapper>
          <StyledMainHeader>
            The Sky Is The Limit When It Comes To Making Your{" "}
            <strong>Perfect Training Courses.</strong>
          </StyledMainHeader>

          <StyledSignUpButton
            href="https://app.virti.com/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign Up
          </StyledSignUpButton>
          <StyledSubHeading>Want to learn more?</StyledSubHeading>
          <StyledTextDescription>
            <StyledSignInLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.virti.com/contact-sales?app=safety-training-marketing"
            >
              Book a call
            </StyledSignInLink>{" "}
            with our sales team.
          </StyledTextDescription>
        </StyledTextWrapper>
        <StyledIframeWrapper>
          <StyledIframe
            src="https://virti-onboarding.s3.eu-west-2.amazonaws.com/Hype+trailer.mp4"
            title="virti video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></StyledIframe>
        </StyledIframeWrapper>
        <StyledSlashWrapper>
          <StyledSlash></StyledSlash>
        </StyledSlashWrapper>
      </StyledHomepage>
      <StyledFooter />
    </Page>
  );
};

Home.propTypes = {};

export default Home;
