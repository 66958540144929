import styled, { css } from "styled-components/macro";

export const StyledTextWrapper = styled.div`
  margin-top: 0rem;
  margin-left: 0rem;
  display: grid;
  @media (max-width: 600px) {
    padding: 25px 25px 0px 25px;
  }
`;
export const StyledHomepage = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  font-family: Ubuntu, sans-serif;
  height: 100vh;
  overflow: hidden;
  position: relative;
  @media (max-width: 1200px) {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: 600px) {
    margin-top: 15px;
    margin-bottom: -50px;
  }
`;

export const StyledMainHeader = styled.h1`
  color: var(--new-secondary-color);
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: 100px;
  /* line-height: 2.8rem; */
  width: 30rem;
  @media (min-width: 1500px) {
    font-size: 3.2rem;
    width: 35rem;
  }
  @media (min-width: 1700px) {
    font-size: 3.7rem;
    width: 40rem;
  }
  @media (max-width: 1150px) {
    margin: 0px auto 20px 0px;
  }
  @media (max-width: 600px) {
    font-size: 2rem;
    width: 100%;
    word-break: break-word;
  }
`;

export const StyledSubHeading = styled.h2`
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 0rem;
  @media (max-width: 1500px) {
    font-size: 1.8rem;
  }
`;

export const StyledTextDescription = styled.h3`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const StyledSignInLink = styled.a`
  color: var(--primary-color);
  text-decoration: none;
`;
export const StyledSignUpButton = styled.a`
  text-align: center;
  color: var(--primary-button-text-color);
  padding: 0.6rem;
  display: inline-block;
  border-radius: var(--primary-button-border-radius);
  border: none;
  text-decoration: none;
  font-size: 1.2rem;
  width: 10rem;
  margin: 0rem 0rem 2.5rem 0rem;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: var(--primary-color);
  font-family: Ubuntu, sans-serif;
  &:hover {
    opacity: 0.9;
    background-color: ${(props) =>
      props.bgColorHover ? props.bgColorHover : ""};
    color: var(--primary-button-text-color-hover);
    ${(props) =>
      props.type === "secondary" &&
      css`
        background-color: var(--secondary-button-bg-color-hover);
        color: var(--secondary-button-text-color-hover);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      css`
        background-color: var(--tertiary-button-bg-color-hover);
        color: var(--tertiary-button-text-color-hover);
      `}
    ${(props) =>
      props.noBg &&
      css`
        text-decoration: underline;
      `}
  }
  &:active {
    ${(props) =>
      props.type === "primary" &&
      !props.noBg &&
      css`
        background-color: var(--primary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "secondary" &&
      !props.noBg &&
      css`
        background-color: var(--secondary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      !props.noBg &&
      css`
        background-color: var(--tertiary-button-bg-color-active);
      `}
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-text-color);
      background-image: none;
      cursor: default;
      &:hover {
        background-image: none;
        background-color: var(--button-disabled-bg-color);
        color: var(--button-disabled-text-color);
      }
    `}
`;
export const StyledSlashWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const StyledSlash = styled.div`
  background: var(--secondary-color);
  width: 60rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0rem;
  clip-path: polygon(38% 0, 100% 0%, 100% 100%, 21% 100%);

  z-index: -1;
  @media (max-width: 1150px) {
    width: 80%;
    display: none;
  }
`;

export const StyledIframeWrapper = styled.div`
  width: 100%;
  padding: 50px;
  display: flex;
  align-items: end;
  @media (max-width: 1150px) {
    margin-top: 20px;
    background: var(--secondary-color);
  }
`;
export const StyledIframe = styled.iframe`
  width: 600px;
  height: 337.5px;
  margin: auto;
  margin-top: 70px;
  border-radius: 22px;
  border: none;
  max-width: 90%;
  @media (min-width: 1400px) {
    width: 800px;
    min-height: 200px;
  }
  @media (max-width: 1150px) {
    margin-top: 0px;
  }
  @media (max-width: 600px) {
    margin: auto;
    min-height: 200px;
    max-height: 80%;
  }
`;

export const StyledFooter = styled.div`
  background: var(--secondary-color);
  height: 100%;
`;
