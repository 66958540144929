import styled from "styled-components/macro";

export const StyledPage = styled.div``;

export const StyledPageContent = styled.div``;

export const StyledNav = styled.nav`
  background: var(--primary-color);
  padding: 8px 5px;
  display: flex;
  align-items: center;
`;

// export const LogoSvg = styled()`
//   width: 25px;
//   color: var(--primary-font-color);
//   padding: 30px var(--sidebar-item-padding);
//   transition: padding var(--sidebar-transition-time, 0.3s);
//   box-sizing: content-box;
// `;
